exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-indeks-form-niepoprawnych-js": () => import("./../../../src/pages/indeks-form-niepoprawnych.js" /* webpackChunkName: "component---src-pages-indeks-form-niepoprawnych-js" */),
  "component---src-pages-indeks-odpowiednikow-angielskich-js": () => import("./../../../src/pages/indeks-odpowiednikow-angielskich.js" /* webpackChunkName: "component---src-pages-indeks-odpowiednikow-angielskich-js" */),
  "component---src-pages-indeks-odpowiednikow-cekiewnoslowianskich-js": () => import("./../../../src/pages/indeks-odpowiednikow-cekiewnoslowianskich.js" /* webpackChunkName: "component---src-pages-indeks-odpowiednikow-cekiewnoslowianskich-js" */),
  "component---src-pages-indeks-odpowiednikow-greckich-js": () => import("./../../../src/pages/indeks-odpowiednikow-greckich.js" /* webpackChunkName: "component---src-pages-indeks-odpowiednikow-greckich-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podstawowy-indeks-hasel-js": () => import("./../../../src/pages/podstawowy-indeks-hasel.js" /* webpackChunkName: "component---src-pages-podstawowy-indeks-hasel-js" */),
  "component---src-pages-words-js": () => import("./../../../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */),
  "component---src-pages-wyniki-js": () => import("./../../../src/pages/wyniki.js" /* webpackChunkName: "component---src-pages-wyniki-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

